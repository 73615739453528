//Firebase setup

//Note for version! https://forum.vuejs.org/t/vue3-dependency-not-found-for-firebase/117733
let firebaseConfig;

//If CAN
if(process.env.VUE_APP_COUNTRY && process.env.VUE_APP_COUNTRY == 'CAN')
{
	// eventually just one firebase project for Canada
	if(process.env.VUE_APP_ENVR === 'prod')
	{
		firebaseConfig = {
			apiKey: "AIzaSyBwNqNFPd8UCr9LzODp9r0Es9QTpLZ8PxQ",
			authDomain: "wcc-national-account-prod.firebaseapp.com",
			databaseURL: "https://wcc-national-account-prod-default-rtdb.firebaseio.com",
			projectId: "wcc-national-account-prod",
			storageBucket: "wcc-national-account-prod.appspot.com",
			messagingSenderId: "602096111338",
			appId: "1:602096111338:web:aefe577c528feaf4081193"
		};
	}
	else
	{
		firebaseConfig = {
			apiKey: "AIzaSyDapkvpGeapdr2BoXX6hY0MAC9vFBc3f10",
			authDomain: "wcc-national-account-dev.firebaseapp.com",
			databaseURL: process.env.NODE_ENV === 'production' ? "https://wcc-national-account-dev-default-rtdb.firebaseio.com" : "http://localhost:9000/?ns=wcc-national-account-dev",
			projectId: "wcc-national-account-dev",
			storageBucket: "wcc-national-account-dev.appspot.com",
			messagingSenderId: "280599160744",
			appId: "1:280599160744:web:05b5af3456168e8ffddf9d"
		};
	}
}
	
//Else - US
else
{
	firebaseConfig = {
		apiKey: "AIzaSyBCykf-y2DE_e_gVjB0eKIfIWuzqN6TZUs",
		authDomain: "large-account-portal-prod.firebaseapp.com",
		databaseURL: process.env.NODE_ENV === 'production' ? "https://large-account-portal-prod-default-rtdb.firebaseio.com" : "http://localhost:9000/?ns=large-account-portal-prod",
		projectId: "large-account-portal-prod",
		storageBucket: "large-account-portal-prod.appspot.com",
		messagingSenderId: "831305087868",
		appId: "1:831305087868:web:5ac868a31d4044fa27c2f9"
	};

}

export default firebaseConfig;